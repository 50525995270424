import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Hahnwald: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Hahnwald,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Hahnwald"
          points="643.8,914.6 632,948 618,942 562,954 556,878 570,892 612,892 632,912"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 569.9078 926.3391)"
        >
          Hahnwald
        </text>
      </g>
    </>
  );
});
